// src/components/Instructions.js
import React from "react";

const Instructions = () => {
  return (
    <div className="flex justify-center py-16 px-4 bg-gray-100">
      <div className="bg-white rounded-lg shadow-md p-8 w-full max-w-5xl">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Gifted Child Online Entrance Examination into Ogun Science Academy
        </h2>
        <div className="text-gray-700 space-y-4">
          <p>
            <strong>Step 1: Eligibility Check</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Age: 10-12 years old</li>
            <li>Academic performance: Top 10% of current school</li>
            <li>
              Interest in STEM fields (Science, Technology, Engineering, and
              Mathematics)
            </li>
          </ul>
          <p>
            Visit the Ogun Science Academy website:{" "}
            <a
              href="ogunscienceacademy.com.ng/admissions"
              className="text-blue-500 underline"
            >
              giftedchildexam@ogunscienceacademy.com.ng
            </a>
          </p>

          <p>
            <strong>Step 2: Registration</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Click "Apply Now" on the website</li>
            <li>Fill out the online registration form:</li>
            <ul className="list-decimal pl-8">
              <li>Personal details (Name, Date of Birth, Address)</li>
              <li>Academic details (Current school, Grades)</li>
              <li>Parent/Guardian information</li>
            </ul>
            <li>Submit the registration form</li>
          </ul>

          <p>
            <strong>Step 3: Payment</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Pay the non-refundable examination fee of N10,000</li>
            <li>
              Payment methods: Online banking, Debit/Credit card, or Bank
              transfer
            </li>
            <li>Receipt will be generated automatically</li>
          </ul>

          <p>
            <strong>Step 4: Examination Schedule</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Receive examination schedule via email/SMS:</li>
            <ul className="list-decimal pl-8">
              <li>Date</li>
              <li>Time</li>
              <li>Examination link</li>
            </ul>
          </ul>

          <p>
            <strong>Step 5: Examination</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Log in to the examination portal using credentials provided</li>
            <li>
              Complete the online examination within the allotted time (2 hours)
            </li>
            <li>Examination format:</li>
            <ul className="list-decimal pl-8">
              <li>Multiple Choice Questions (MCQs)</li>
              <li>Essay questions</li>
            </ul>
          </ul>

          <p>
            <strong>Step 6: Results</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>
              Receive examination results via email/SMS within 3-5 working days
            </li>
            <li>Results will indicate:</li>
            <ul className="list-decimal pl-8">
              <li>Pass or Fail</li>
              <li>Score</li>
            </ul>
          </ul>

          <p>
            <strong>Step 7: Interview</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>
              Shortlisted candidates may be invited for an online interview
            </li>
            <li>Interview details will be communicated via email/SMS</li>
          </ul>

          <p>
            <strong>Step 8: Admission</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Successful candidates will receive admission offers</li>
            <li>
              Payment of Acceptance Fee and registration procedures will follow
            </li>
          </ul>

          <p>
            <strong>Important Dates</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Registration opens: Sunday, 1st December, 2024</li>
            <li>Registration closes: Friday, 11th April, 2025</li>
            <li>Examination date: Saturday, 12th April, 2025 (10 am - 5 pm)</li>
            <li>Results release: Monday, 21st April, 2025</li>
          </ul>

          <p>
            <strong>Contact Information</strong>
          </p>
          <ul className="list-disc pl-8">
            <li>Phone: 080 71466666</li>
            <li>
              Email:{" "}
              <a
                href="mailto:info@ogunscienceacademy.com.ng"
                className="text-blue-500 underline"
              >
                info@ogunscienceacademy.com.ng
              </a>
            </li>
            <li>
              Website:{" "}
              <a
                href="http://www.ogunscienceacademy.com.ng"
                className="text-blue-500 underline"
              >
                www.ogunscienceacademy.com.ng
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
