// src/components/AdButton.js
import React from "react";

const AdButton = () => {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <a
        href="/admissions" // Or link it to the registration page or section
        className="bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition-colors duration-300"
      >
        Apply Now
      </a>
    </div>
  );
};

export default AdButton;
