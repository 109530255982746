import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AdmissionForm from "../components/AdmissionForm";
import Instructions from "../components/Instructions";

const AdmissionPage = () => {
  // State to track visibility of Instructions
  const [showInstructions, setShowInstructions] = useState(false);

  // Toggle function to show/hide instructions
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <div className="flex justify-center items-center py-20 px-4 bg-gradient-to-r from-green-500 to-blue-600 text-white">
        <div className="text-center max-w-2xl">
          <h1 className="text-5xl font-bold mb-6">
            Welcome to Ogun Science Academy
          </h1>
          <p className="text-lg mb-8">
            You can easily enroll for Gifted Child Examination by completing the
            form below.
          </p>
          <p className="text-lg font-semibold">
            Gifted Child Examination for the 2024 academic year is now open!
          </p>
        </div>
      </div>

      {/* Button to Show/Hide Instructions */}
      <div className="flex justify-center my-6">
        <button
          onClick={toggleInstructions}
          className="bg-yellow-500 text-white px-6 py-3 rounded-lg hover:bg-yellow-600 transition duration-300"
        >
          {showInstructions ? "Hide Instructions" : "View Instructions"}
        </button>
      </div>

      {/* Instructions Section - Only visible if showInstructions is true */}
      {showInstructions && <Instructions />}

      {/* Admission Form Section */}
      <div className="flex justify-center py-16 px-4 flex-grow">
        <div className="bg-white rounded-lg shadow-2xl p-10 w-full max-w-4xl">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Apply for Gifted Child Examination
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Complete the form below to start your registration process at Ogun
            Science Academy. We are dedicated to offering a rich educational
            experience that prepares your child for a successful future.
          </p>

          {/* Admission Form */}
          <AdmissionForm />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AdmissionPage;
